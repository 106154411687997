import { useState } from 'react';
import { db } from '../db';
import PhotoAction from './PhotoAction';


function usePhotoState() {

  const [mode, setMode] = useState("list")
  const [error, setError] = useState(null)
  const [media, setMedia]= useState(null)
  const [item, setItem] = useState(null)
  const [list, setList] = useState(null)
  const [updated_at, setUpdatedAt] = useState(null)
  
  const [deleted_at, setDeletedAt] = useState(null)


  const deleteHandler = () => {
    setDeletedAt(new Date())
  }
  const loadedHandler = (data) => {

    if(data){
      setItem(data.data)
    }
      //setItem(new Movie(data.video_id, data.title, data.thumbnail_url))
  }



  const updatedHandler = (data) => {

    if(data){
      
      setItem(data.data)
      setUpdatedAt(new Date())
    }
    //setItem(new Movie(data.video_id, data.title, data.thumbnail_url))
  }
  const loadedListHandler = (data) => {
    if(data){
      setList(data.data.reverse())
      setUpdatedAt(new Date())
    }

  }

  const loadedCounterHandler = (data) => {
    if(data){
      getList(data.token)
    }
  }

  const errorHandler = (error) => {
    if(error){
      setError(error)
    }
  }


  const mediaLoadedHandler = (data) => {
    setMedia(data)
  } 

  const updateMode = (m) => {
    setMode(m)
  } 
  

  const get = (token, id,) => {
    PhotoAction.get(token, id, loadedHandler)
  } 
  

  const createMedia = (token, files, room_id) => {
    for(var i = 0; i < files.length; i++){
      const file = files[i]
      if(i === 0){
        PhotoAction.createMedia(token, file, room_id, loadedCounterHandler, errorHandler)
      }else{
        setTimeout(() => {
          PhotoAction.createMedia(token, file, room_id, loadedCounterHandler, errorHandler)
        }, "3000");
      }
     
    }
  } 

  const getMedia = (token, url,) => {
      db.photos.get(url).then(function(value){
     
        if(value){
          setMedia(value.data)
        }else{
          PhotoAction.getMedia(token, url, mediaLoadedHandler)
        } 
      });
     
      
  } 
  

  const getList = (token) => {
    PhotoAction.getList(token, loadedListHandler)
  } 

  const commentUpdate = (token, id, comment) => {
    PhotoAction.update(token, id, comment, updatedHandler)
  } 

  const deleteItem = (token, id) => {
    PhotoAction.delete(token, id, deleteHandler)
  } 


  const deleteItems = (token, ids) => {
    for(var i = 0; i < ids.length; i++){
      PhotoAction.delete(token, ids[i], deleteHandler)
    }
  } 

  return {mode,error, media, photo_list:list, photo_item:item, list_updated_at:updated_at, updated_at, deleted_at, updateMode,getMedia, createMedia, getPhotoList:getList, getPhoto:get, commentUpdate, deletePhoto:deleteItem, deletePhotos:deleteItems};
}

export default usePhotoState