import React, { useEffect, useState  } from 'react';
import usePhotoState from './usePhotoState';
import { PhotoItem } from './PhotoItem';
import useAuthState from '../stories/auth/useAuthState';

function PhotoListMobApp(props:PropsValue) {
  
  const [list, setList] = useState(null)
  const {photo_list, list_updated_at, getPhotoList} = usePhotoState()
  const {token, getToken} = useAuthState()

  useEffect(() => {
    getToken()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(() => {
    if(token){
      getPhotoList(token)
    }
    
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);
  
  const clickHandler = (id) => {
    window.location.href = "/tab/detail/" + id
    
  }

  useEffect(() => {
    if(photo_list){
      setList(photo_list.map((item, index) => {  
        return <PhotoItem photo={item} index={index} token={token} clickHandler={clickHandler} />
      }))
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at]);


  return(
    <div className="tab photo-list">
      <div className="main">
        <div>
        <div className="photo-wrap">{list}</div>
        </div>
      </div>
      
    </div>
  )
}

export default PhotoListMobApp
