// db.ts
import Dexie, { Table } from 'dexie';

export interface Photo {
  name: string;
}

export class PhotoDexie extends Dexie {

  photos!: Table<Photo>;

  constructor() {
    super('myDatabase');
    this.version(1).stores({
      photos: 'name' 
    });
  }


}

export const db = new PhotoDexie();