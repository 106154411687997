import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';

import AuthApp from './auth/AuthApp.js';
import PhotoListMobApp from './photo/PhotoListMobApp.js';
import PhotoDetailMobApp from './photo/PhotoDetailMobApp.js';
import PhotoListApp from './photo/PhotoListApp.js';
import PhotoDetailApp from './photo/PhotoDetailApp.js';
import PhotoSlideShowApp from './photo/PhotoSlideShowApp.js';
config.set(configuration);

class App extends React.Component{

render(){
return(
    <div className="main">
      <Routes>
        <Route  path='/auth/:type' element={<AuthApp />} />
        <Route  path='/auth/:type/:id' element={<AuthApp />} />
        <Route  path='/mob/' element={<PhotoListMobApp />} />
        <Route  path='/mob/:room_id' element={<PhotoListMobApp />} />
        <Route  path='/mob/detail/:id' element={<PhotoDetailMobApp />} />
        <Route  path='/tab/' element={<PhotoListApp />} />
        <Route  path='/tab/detail/:id' element={<PhotoDetailApp />} />
        <Route  path='/tab/slideshow' element={<PhotoSlideShowApp />} />
      </Routes>   
    </div>
)}}

const container = document.getElementById('root')

if(container){
  const root = createRoot(container)
  root.render((<BrowserRouter ><App /></BrowserRouter>))
}
