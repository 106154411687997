import React, { useEffect, useState  } from 'react';

import usePhotoState from './usePhotoState';
import { PhotoItem } from './PhotoItem';
import { Footer } from '../footer/Footer';
import { DeleteWindow } from '../stories/delete_window/DeleteWindow';
import useAuthState from '../stories/auth/useAuthState';

function PhotoListMobApp(props:PropsValue) {
  const [message, setMessage] = useState(null)
  const [selected, setSelected] = useState(null)
  const [delteItems, setDeleteItems] = useState([])
  const [delete_item, setDeleteItem] = useState(null)
  const [delete_window, setDeleteWindow] = useState(null)
  //const {room_id} = useParams()
  //console.log(room_id)
  const [change_at, setChangeAt] = useState([])
  const [list, setList] = useState(null)
  const {mode, error, photo_list,deleted_at, list_updated_at, updateMode, getPhotoList, createMedia, deletePhotos} = usePhotoState()
  const {token,room_id, getToken,getId} = useAuthState()
  useEffect(() => {
    if(token){
      getPhotoList(token)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);



  useEffect(() => {
    if(error){
      if(error && error.error.code === 300){
        setMessage(<div><div className="error_message">{error.error.message}</div> <div  onClick={deleteModeHandler} className="error_link" ><i className="fa-solid fa-trash"></i>&nbsp;削除する写真を選択</div> </div>)
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[error]);



  useEffect(() => {
    getToken()
    getId()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(() => {
    if(deleted_at){
      getPhotoList(token)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[deleted_at]);
  


  const clickHandler = (id) => {
    if(mode === "list"){
      window.location.href = "/mob/detail/" + id
    }else{
  
      if(delteItems.findIndex(element => element === id) === -1){
        delteItems.push(id)
        setDeleteItems(delteItems)
        setChangeAt(new Date())
      }else{
        delteItems.splice(delteItems.findIndex(element => element === id),1)
        setDeleteItems(delteItems)
        setChangeAt(new Date())
      }
    }
  }

  useEffect(() => {
    if(delete_item){
      setDeleteWindow(<DeleteWindow message={"選択した写真を削除してよろしいですか。"} closeHandler={deletCloseHandler}  executeHandler={deleteExe}/>)
    }else{
      setDeleteWindow(null)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[delete_item]);

  useEffect(() => {
    if(photo_list){
      setList(photo_list.map(item => {  
        const is_delete = delteItems.length > 0 ? delteItems.findIndex(element => element === item.id) : -1
        return <PhotoItem photo={item} clickHandler={clickHandler} token={token} key={"p_" + item.id} mode={is_delete >= 0 ? "delete" : "list"}/>
      }))
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at, delteItems,change_at, mode]);

  const selectPhotoHandler = (e) => {
    setSelected(e.target.files)
  }

  const updatePhoto = () => {
    
    createMedia(token, selected, room_id)
    setSelected(null)
  }

  const deletePhoto = () => {
    setDeleteItem(new Date())
  }


  const deleteExe = () => {
    setMessage(null)
    deletePhotos(token, delteItems)
    setDeleteItems([])
    setDeleteItem(null)
    updateMode("list")
    
    
  }
  const deletCloseHandler = () => {
    setDeleteItem(null)
  }
  const deleteModeHandler = () => {
    updateMode("delete")
    
  }


  return(
    <div className="mob photo-list">
       
        
      <div className="photo-wrap">{list}</div>
      { mode === "delete" ? <div className={delteItems.length > 0 ? "delete-box" : "delete-box disabled"}><div className="btn" onClick={() => deletePhoto()}>選択した写真を削除</div></div>: null  }
      {mode === "list" ?
      <div className="new-btn-box-wrap">
        <div className="new-btn-box">
          {message}
          <label className="file__label">
          新しい写真を共有
          <input type="file" name="file"  onChange={selectPhotoHandler} multiple accept="image/*"  />
          </label>
          <input type="file" className="btn"/>
          {selected && selected.length > 0 ?  <div class="btn" onClick={() => updatePhoto()}><i className="fa-solid fa-arrow-up-from-bracket"></i> 選択した写真を共有</div> : null}
        </div>
      </div>
      :null}
      {delete_window}
      <Footer selected="list"deleteModeHandler={deleteModeHandler} />
    </div>
  )
}

export default PhotoListMobApp
