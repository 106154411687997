import { useState } from 'react';
import AuthAction from './AuthAction';

function useAuthState() {

  
  const [error, setError] = useState(null)
  const [token, setToken] = useState(null)
  const [id, setId] = useState(null)

  const completedHandler = (data) => {
    setToken(data)
  }

  const completeIddHandler = (data) => {
    setId(data)
  }

  const errorHandler = (data) => {
    setError(data)
  }

  const getToken = () => {
    AuthAction.getToken(completedHandler, errorHandler)
  }

  const getId = () => {
    AuthAction.getId(completeIddHandler, errorHandler)
  }
  

  return { token:token, room_id:id, auth_error:error, getToken:getToken, getId};
}

export default useAuthState