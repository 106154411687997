import React, {useEffect,useState  } from 'react';
import { useParams } from 'react-router-dom';
import { PhotoDetail } from './PhotoDetail';
import usePhotoState from './usePhotoState';
import useAuthState from '../stories/auth/useAuthState';

function PhotoDetailMobApp(props:PropsValue) {
 
  const {id} = useParams()
  const [item, setItem] = useState(null)

  const {photo_item, photo_list, getPhoto, getPhotoList} = usePhotoState()
  const {token, getToken} = useAuthState()

  useEffect(() => {
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
 },[]);


  useEffect(() => {
    if(token){
      getPhoto(token, id)
      getPhotoList(token)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);


  useEffect(() => {
    if(photo_item){
      setItem(photo_item)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[photo_item]);

  
  const moveTo = (url) => {
    window.location.href = url
  }

  const goPrev = () => {
    const my_position = findIndex()
    if(my_position <= 0) return null 
    else{
      window.location.href = "/tab/detail/" + photo_list[my_position - 1].id
    }
  }

  const goNext = () => {
    const my_position = findIndex()
    if(my_position >= photo_list.length -1) return null 
    else{
      window.location.href = "/tab/detail/" + photo_list[my_position + 1].id
    }
  }


  const findIndex = () => {
    if(photo_list) {
       return photo_list.findIndex(item => item.id === id)
    }else{
      return -1
    }
  }
  return(
    <div className="tab photo-detail-wrap">
      <div className="main">
        
        {item && token ? <PhotoDetail token={token} photo={photo_item} notice={null} clickHandler={null} deleteHandler={null} /> : null}
        <div className="left_btn" onClick={goPrev}><i className="fa-solid fa-circle-arrow-left"></i></div>
        <div className="right_btn" onClick={goNext}><i className="fa-solid fa-circle-arrow-right"></i></div>
      </div>
    <div className="btns">
      <div className="btns-item" onClick={()=>moveTo("/tab/")}><div className="label">写真一覧</div></div>
    </div>
  </div>
  )
}

export default PhotoDetailMobApp
