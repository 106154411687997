import React, {useEffect,useState  } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useLoginState from './useLoginState';


function AuthApp(props:PropsValue) {
 

  const {type} = useParams()
  const [searchParams] = useSearchParams();
  const [error_message, setErrorMessage] = useState(null)
  const id = searchParams.get("roomId") ?  searchParams.get("roomId") : "" ;
  const member_id = searchParams.get("memberId") ?  searchParams.get("memberId") : "" ;

  
  const {token, loginError, login} = useLoginState()
  
  useEffect(() => {
    login(id, member_id)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
 
  useEffect(() => {
    if(loginError){
      setErrorMessage("このIDは許可されていません。ご確認ください。")
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loginError]);
 


  useEffect(() => {
    if(token){
      if(type === "mob")
       window.location.href = "/mob/" + id
      else
      window.location.href = "/tab/"
    }
    
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);



  return(
    <div>{error_message}</div> 
  )
}

export default AuthApp
