
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'

import Action from '../action/Action';

const LoginAction = {


  login( id, member_id, callback, errorHandler){

    var data = new FormData() 
    data.append('grant_type', "password")
    data.append('username', id)
    data.append('password', member_id)
    data.append('scope', "user")
    
    let headers = new Headers();
    headers.append('Authorization', 'Basic ' + btoa(config.get("CLIENT_ID") + ":" + config.get("CLIENT_SECRET")));
    Action.fetchJson( config.get("API_PATH") + config.get("API_AUTH") , "POST", headers, data,  callback, errorHandler, {id:id})

  },

  hasToken(){
    const token =  cookie.load("token")

    if(token) return true 
    return false
  },

  logout(){
    cookie.remove('token', { path: '/' })
    cookie.remove('refresh_token', { path: '/' })
    cookie.remove('id', { path: '/' })
  }
   
 
}

export default LoginAction
