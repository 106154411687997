
import 'whatwg-fetch'
//import LoginAction from '../components/login/LoginAction'


const Error = {


  
  authError(){
    //LoginAction.logout()
    window.location.href = "/error"
  }
 
}

export default Error
