import React,{  } from 'react';
import PropTypes from 'prop-types';

export const Footer = ({
  selected,
  deleteModeHandler

}) => {
  

  const moveTo = (url) => {
    window.location.href = url
  }


  return(
    <div className="footer" >
       <div className="footer-item" onClick={() => moveTo("tqspapp://open")}><div className="icon"><i className="fa-solid fa-house"></i></div><div className="label">ホーム</div></div>
      <div className="footer-item" onClick={()=>moveTo("/mob/")}><div className="icon"><i className="fa-solid fa-images"></i></div><div className="label">一覧</div></div>
      {selected === "list" ? <div className="footer-item" onClick={() => deleteModeHandler()}><div className="icon"><i className="fa-solid fa-trash"></i></div><div className="label">削除</div></div> : null}
    </div>
);}

Footer.propTypes = {
  selected:PropTypes.string,
  deleteModeHandler: PropTypes.func,

};

Footer.defaultProps = {
  selected:null,
  deleteModeHandler:()=>{},
};