import { useState } from 'react';
import LoginAction from './LoginAction';
import cookie from 'react-cookies'

function useLoginState() {

  
  const [error, setError] = useState(null)
  const [token, setToken] = useState(null)

  const completedHandler = (data) => {
    //TODO set token on cookie 
    if(data.data && data.data.access_token){
      cookie.save('token', data.data.access_token, { path: '/' })
      cookie.save('refresh_token', data.data.refresh_token, { path: '/' })
      cookie.save('id', data.id, { path: '/' })
      setToken(data.data.access_token)
    }
    
  }

  const errorHandler = (data) => {
    setError(data)
  }

  const login = (id, member_id) => {
    LoginAction.login(id, member_id, completedHandler, errorHandler)
  }

  const logout = () => {
    LoginAction.logout()
  }

  const isLogin = () => {
    LoginAction.hasToken()
  }

    

  return { token:token,  loginError:error, isLogin:isLogin, login:login, logout:logout};
}

export default useLoginState