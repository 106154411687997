import React, {useEffect} from 'react';
import  "./delete_window.css"

interface DeleteWindowProps {
  message:String,
  executeHandler:Function,
  closeHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const DeleteWindow = ({
  message,
  executeHandler,
  closeHandler,
  ...props
}: DeleteWindowProps) => { 

  

  useEffect(() => {
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div className="delete-window" data-id="modal1" >
      <div className="message">{message}</div>
      <div className="button_wrap">
        <div className="button" onClick={closeHandler}>キャンセル</div>
        <div className="button" onClick={executeHandler}>削除</div>
      </div>
    </div>
  );
};

