import React, { useEffect, useState  } from 'react';
import usePhotoState from './usePhotoState';
import Utile from '../utiles/Utile';

function PhotoSlideShowApp(props:PropsValue) {
  
  const [item, setItem] = useState(null)
  const [image_class, setImageClass] = useState(null)
  const [curren_id, setCurrentId] = useState(-1)
  const [slide_list, setSlidLiest] = useState([])
  const {photo_list, list_updated_at, getPhotoList} = usePhotoState()
  

  useEffect(() => {
    getPhotoList("token")
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(curren_id >= 0){
      const photo = slide_list[curren_id]
      if(photo){
        setItem(<img src={photo.photo} alt="スライド"/>)
        setImageClass("show")
      }
      setTimeout(hideImage,5000)
    }
  
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[curren_id]);

  useEffect(() => {
    if(slide_list && slide_list.length > 0){
      setCurrentId(0)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[slide_list]);
  const hideImage = () => {
    setImageClass("hide")
    setTimeout(changePhoto,600)
  }
  const changePhoto = () => {
    console.log("changePhoto")
    
    if(curren_id + 1 < slide_list.length ){

      setCurrentId(curren_id + 1)
    }else{
      setCurrentId(0)
    }
  }
  const clickHandler = (id) => {
    window.location.href = "/tab/"
    
  }

  useEffect(() => {
    if(photo_list){
     setSlidLiest(Utile.shuffleArray(photo_list))
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at]);



  return(
    <div className="tab photo-slide" onClick={clickHandler}>
     <div className={"slide-show-item " + image_class}>
      {item}
     </div> 
    </div>
  )
}

export default PhotoSlideShowApp
